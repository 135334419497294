<template>
  <div id="login-page">
    <div class="login-logo mb-4">
      <p class="mb-0">DES Sanayi Sitesi</p>
      <small>Güvenlik Paneli</small>
    </div>
    <form @submit.prevent="login">
      <base-input
        id="text"
        mode="text"
        class="mb-2"
        v-model="username"
        placeholder="Kullanıcı Adınız"
      >
        Kullanıcı Adınız
      </base-input>
      <base-input
        id="password"
        mode="password"
        v-model="password"
        placeholder="Şifreniz"
      >
        Şifreniz
      </base-input>
      <base-button value="Giriş Yap" class="mt-3 w-100"> </base-button>
      <div class="error-box">
        {{ error }}
      </div>
    </form>
  </div>
</template>

<script>
import BaseInput from "../UI/BaseInput.vue";
import BaseButton from "../UI/BaseButton.vue";
export default {
  components: { BaseInput, BaseButton },
  data() {
    return {
      username: "",
      password: "",
      error: null,
    };
  },
  methods: {
    async login() {
      const data = {
        username: this.username,
        password: this.password,
      };
      try {
        await this.$store.dispatch("login", data);
        this.$router.replace("/dashboard");
      } catch (error) {
        this.error = error.message || "Kullanıcı adı ya da şifreniz hatalı";
      }
    },
  },
};
</script>

<style>
#login-page {
  display: flex;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.error-box {
  position: relative;
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  transition: all 1s linear;
}
</style>
