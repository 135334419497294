<template>
  <section class="page-container">
    <div class="container">
      <div class="row">

        <div class="col-6 mt-4" v-for="(c, index) in companyId" :key="index">
          <div class="col-12 mb-4">
            <base-page-title :title="c.OZEL_KOD.length == 8 ? 'Dükkan Sahibi' : 'Kiracı'"></base-page-title>
          </div>
          <div class="col-12"><b> Firma Adı: </b> {{c.CARİ_KOD}}</div>
          <div class="col-12">
            <b> Adres: </b> {{c.ADRES}}
          </div>
          <div class="col-12" v-show="c.MAIL_ADRES != ''"><b> E-Posta: </b> {{c.MAIL_ADRES}}</div>
          <div class="col-12" v-show="c.TELEFON_NO != ''"><b> Telefon: </b> {{c.TELEFON_NO}}</div>
          <div class="col-12" v-show="c.GSM_NO != ''"><b> Telefon (GSM): </b> {{c.GSM_NO}}</div>
          <div class="col-12" v-show="c.WEADRES != ''"><b> Web Adresi: </b> {{c.WEADRES}}</div>
        </div>

      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="İçerik Güncellendi"
    >
    </base-notification>
  </section>
</template>

<script>
import BasePageTitle from "../../../components/UI/BasePageTitle";
import BaseNotification from "../../../components/UI/BaseNotification";

import { mapGetters } from "vuex";

export default {
  components: { BasePageTitle, BaseNotification },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      itemId: this.$route.params.id,
      isLoading: false,
      itemSuccess: false,
      title: null,
      image: null,
      editorData: null,
      slug: null,
      keywords: null,
    };
  },

  mounted() {
    this.fetchCompanyId();
  },

  created() {
    //this.status = this.specialcode2.substr(-2) == ".0" ? "Kiracı" : "Dükkan Sahibi";

  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
    companyId() {
      return this.$store.getters["companyId"];
    }
  },

  methods: {
    async fetchCompanyId() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchCompanyId", this.id);
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
    getCompany() {
      return this.$store.getters["companyId"];
    },
  },
};
</script>

<style></style>
