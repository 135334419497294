<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Hesap Bilgileri"></base-page-title>
        </div>
        <form ref="profileForm" @submit.prevent="onSubmit">
          <div class="container py-3">
            <div class="row">
               <div class="col-6 mb-2">
                <base-input
                  id="fullname"
                  mode="text"
                  class="mb-2"
                  v-model="fullname"
                  label="Ad Soyad"
                >
                </base-input>
              </div>
              <div class="col-6 mb-2">
                <base-input
                  id="email"
                  mode="text"
                  class="mb-2"
                  v-model="email"
                  label="E-Posta"
                >
                </base-input>
              </div>
             
              <div class="col-6 mb-2">
                <base-input
                  id="phone"
                  mode="text"
                  class="mb-2"
                  v-model="phone"
                  label="Telefon"
                >
                </base-input>
              </div>
              
              <div class="col-6 mb-2">
                <base-input
                  id="landPhone"
                  mode="text"
                  class="mb-2"
                  v-model="password"
                  label="Şifre"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 d-flex justify-content-end">
                <base-button value="Kaydet" class="mt-3 w-100"> </base-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <base-notification
      v-if="itemSuccess"
      notificationMessage="Hesap Bilgileri Güncellendi!"
    ></base-notification>
  </section>
</template>

<script>
import BaseInput from "../components/UI/BaseInput";
import BaseButton from "../components/UI/BaseButton";
import BasePageTitle from "../components/UI/BasePageTitle";
import BaseNotification from "../components/UI/BaseNotification";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { BaseInput, BaseButton, BasePageTitle, BaseNotification },

  data() {
    return {
      isLoading: false,
      itemSuccess: false,
      fullname: this.$store.getters.whoami.email,
      email: this.$store.getters.whoami.email,
      phone: this.$store.getters.whoami.phone,
    };
  },

  computed: {
    ...mapGetters({
      token: "token",
    }),
  },

  async created() {
    await this.fetchWhoAmI();
    this.fullname = this.$store.getters.whoami.fullname;
    this.email = this.$store.getters.whoami.email;
    this.phone = this.$store.getters.whoami.phone;
  },

  mounted() {
    this.focusInput();
  },

  methods: {
    focusInput() {
      const inputs = this.$refs.profileForm.querySelectorAll(
        'input:not([type="submit"])'
      );

      Array.from(inputs).map((e) => {
        if (e.value.length > 0) {
          e.previousElementSibling.classList.add("clicked-input");
        }
      });
    },

    async fetchWhoAmI() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchWhoAmI");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("email", this.email);
      formData.append("fullname", this.fullname);
      formData.append("phone", this.phone);
      await axios
        .post("https://api.dessanayi.org/security/whoami/update", formData, {})
        .then((res) => {
          this.itemSuccess = true;
          console.log(res);
        });
      this.isLoading = false;
    },
  },
};
</script>
