<template>
  <section class="page-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <base-page-title title="Tüm Firmalar"></base-page-title>
        </div>
        <div class="col-12"> 
            <div class="row">
                <div class="col-6">
                    <base-input
                        id="keywords"
                        mode="text"
                        class="mb-3"
                        v-model="search"
                        label="Firma Ara"
                        >
                    </base-input>
                </div>
                 <div class="col-6">
                    <select v-model="street" id="pages">
                        <option value="" selected="selected">Cadde</option>
                        <option
                            v-for="street in streets"
                            :key="street"
                            :value="street"
                            >{{ street }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="loading" v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div
          v-for="(listing, index) in filtered"
          :key="index"
          class="col-lg-3 col-12 mb-3"
        >
          <div v-if="!isLoading" class="item text-center p-4">
           
            <div class="item-img is-equal-image">
              <router-link
                :to="{
                  name: 'company-detail',
                  params: {
                    id: listing.OZEL_KOD
                  },
                }"
              >
            <h5> {{listing.CARİ_KOD}}</h5>
            <hr>
            <div>
                <div>
                    <b> Telefon: </b> {{listing.TELEFON_NO}}
                </div>
            </div>
            <div>
                <div>
                    <b> Adres: </b> {{listing.ADRES}}
                </div>
            </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BasePageTitle from "../../components/UI/BasePageTitle";
import BaseSpinner from "../../components/UI/BaseSpinner";
import { mapGetters } from "vuex";
import BaseInput from "../../components/UI/BaseInput";

export default {
  components: {
    BasePageTitle,
    BaseSpinner,
    BaseInput
  },
  data() {
    return {
      isLoading: false,
      search: '',
      street: ''
    };
  },
  computed: {
    listings() {
      return this.$store.getters["company"];
    },
    filtered() {
      var list = [];
        Array.from(this.getListings()).map((value) => {
          if(value.CARİ_KOD.toLocaleLowerCase("tr-TR").match(this.search.toLocaleLowerCase("tr-TR")) && (this.street != "" ? value.OZEL_KOD.substr(0, 3) == this.street ? true : false : true)) {
            list.push(value);
          }       
        });
      return list;
    },
    streets() {
        var streets = [];
        Array.from(this.getListings()).map((value) => {
            var s = value.OZEL_KOD.substr(0, 3);
            if(!streets.includes(s) && !["1", "2", "3", "4", "5", "6", "7", "8"].includes(s)) {
                streets.push(s);
            }
        });
        return streets.sort();
    },
    ...mapGetters({
      token: "token",
    }),
  },
  mounted() {
    this.fetchCompany();
  },
  methods: {
    async fetchCompany() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("fetchCompany");
      } catch (error) {
        this.error = error.message || "Bir şeyler yanlış gitti";
      }
      this.isLoading = false;
    },
    getListings() {
      return this.$store.getters["company"];
    },
  },
};
</script>
