<template>
  <header class="d-flex align-items-center">
    <div
      id="header-left"
      class="d-flex flex-column text-center justify-content-center h-100"
    >
      <p class="mb-0">Des Sanayi Sitesi</p>
      <span>Güvenlik Paneli</span>
    </div>
    <div id="header-right" class="p-3 h-100"></div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: var(--header-height);
}
#header-left {
  width: var(--aside-width);
  background: var(--logo-bg);
  color: #ffffff70;

  span {
    font-size: 0.7em;
    display: block;
  }
}

#header-right {
  width: calc(100% - var(--aside-width));
}
</style>
