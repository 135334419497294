export default {
  async fetchCompany(context) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(
      `https://api.dessanayi.org/security/company`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const responseData = await response.json();

    const company = responseData.data;

    context.commit("fetchCompany", company);
  },
  async fetchCompanyId(context, id) {
    const token = context.rootGetters.token;
    let data = new URLSearchParams();
    data.append(`token`, token);
    const response = await fetch(
      `https://api.dessanayi.org/security/company/information/${id}`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const responseData = await response.json();

    const company = responseData.data;

    context.commit("fetchCompanyId", company);
  },
};
