<template>
  <div class="notification success px-3 py-2">
    {{ notificationMessage }}
  </div>
</template>

<script>
export default {
  props: ["notificationMessage"],
};
</script>

<style lang="scss" scoped>
.notification {
  background: #f9f9f9;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 0.8em;
  top: 5em;
  right: 10px;
  position: fixed;
  opacity: 0;
  z-index: 3;
  visibility: hidden;
  animation-name: notification;
  animation-duration: 1s;
  animation-direction: forwards;
}

@keyframes notification {
  0% {
    opacity: 0;
    visibility: hidden;
    top: 5em;
  }
  50% {
    opacity: 1;
    visibility: visible;
    top: 4em;
  }
  75% {
    opacity: 1;
    visibility: visible;
    top: 4em;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    top: 3em;
  }
}

.success {
  background: #71b86c;
  border-color: #378831;
  color: white;
}
</style>
