<template>
  <h1 class="page-title">
    {{ title }}
  </h1>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: var(--page-title-size);
  font-family: $poppins-medium;
}
</style>
