<template>
  <div id="accordion-menu">
    <div id="accordion-menu-wrapper">
      <div
        v-for="accordionItem in accordionItems"
        :key="accordionItem.accordionTitle"
        class="accordion-item"
      >
        <div class="accordion-title" @click="accordionHandle">
          <img src="./assets/images/dashboard.svg" alt="" />
          {{ accordionItem.accordionTitle }}
          <span class="accordion-minus">-</span>
          <span class="accordion-plus">+</span>
        </div>

        <ul class="accordion-links">
          <li
            v-for="accordionLink in accordionItem"
            :key="accordionLink.accordionUrlText"
          >
            <div
              v-for="accordionLinkItem in accordionLink"
              :key="accordionLinkItem.accordionUrlText"
            >
              <router-link
                v-if="accordionLinkItem.accordionUrlText"
                :to="accordionLinkItem.accordionUrl"
              >
                {{ accordionLinkItem.accordionUrlText }}
              </router-link>
              <div></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div @click="logout">Çıkış Yap</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accordionItems: [
        {
          accordionTitle: "Profil",
          accordionLinks: [
            { accordionUrl: "/edit-profile", accordionUrlText: "Düzenle" },
          ],
        },
        {
          accordionTitle: "Firmalar",
          accordionLinks: [
            { accordionUrl: "/company", accordionUrlText: "Tüm Firmalar" },
          ],
        },
      ],
    };
  },
  methods: {
    accordionHandle(event) {
      const accordionTitles = document.querySelectorAll(".accordion-title");
      const accordionTitlesArr = Array.from(accordionTitles);
      accordionTitlesArr.map((el) =>
        el.nextElementSibling.classList.remove("show-links")
      );
      accordionTitlesArr.map((el) => el.classList.remove("active-accordion"));
      event.target.classList.add("active-accordion");
      event.target.nextElementSibling.classList.add("show-links");
    },

    async logout() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("logout");
        this.$router.replace("/login");
      } catch (error) {
        this.error = error.message || "something went wrong";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
#accordion-menu {
  width: var(--aside-width);
  top: var(--header-height);
  height: calc(100% - var(--header-height));
  color: var(--light-blue);
  background: var(--dark-blue);
  position: fixed;
  padding: 25px 20px 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#accordion-menu-wrapper {
  margin: auto 1em auto 0;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-item .accordion-minus {
  display: none;
}

.active-accordion .accordion-plus {
  display: none;
}
.active-accordion .accordion-minus {
  display: block;
}

.accordion-item ul {
  padding: 0 0 0 15px;
  list-style: none;
  border-left: 3px solid #a2aad6;
  margin-top: 10px;
  display: none;
}

.show-links {
  display: block !important;
}

.accordion-item ul a {
  font-size: 0.8em;
  text-decoration: none;
  color: #a2aad6;
}

.accordion-item .accordion-title {
  font-family: "Poppins";
  position: relative;
  cursor: pointer;
}

.accordion-item .accordion-title img {
  width: 15px;
  object-fit: contain;
  position: relative;
  top: -2px;
  left: -5px;
}
.accordion-item .accordion-title span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
