import { createStore } from "vuex";
import authModule from "./modules/auth/index";
import profileModule from "./modules/profile/index";
import companyModule from "./modules/company/index";

const store = createStore({
  modules: {
    auth: authModule,
    profile: profileModule,
    company: companyModule,
  },
});

export default store;
