<template>
  <input type="submit" :value="value" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
[type="submit"] {
  background: var(--primary-color);
  color: white;
  padding: 10px 15px;
  border: 0;
  border-radius: 5px;
}
</style>
