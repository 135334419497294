<template>
  <div class="form-control">
    <label :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      :type="mode"
      :value="currentValue"
      :placeholder="placeholder"
      ref="test"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="pullLabel"
      @blur="pushLabel"
    />
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: "text",
    },
    id: {
      type: String,
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["update:modelValue"],

  computed: {
    checkInput() {
      if (this.mode == "submit") {
        return "submit";
      }
      return null;
    },
    currentValue() {
      return this.modelValue;
    },
  },

  methods: {
    pullLabel(event) {
      event.target.previousElementSibling.classList.add("clicked-input");
      if (
        event.target.value &&
        event.target.previousElementSibling.classList.contains("clicked-input")
      ) {
        event.target.previousElementSibling.classList.add("clicked-input");
      }
    },
    pushLabel(event) {
      if (
        !event.target.value.length > 0 &&
        event.target.previousElementSibling.classList.contains("clicked-input")
      ) {
        event.target.previousElementSibling.classList.remove("clicked-input");
      }
    },
  },
};
</script>

<style lang="scss" scope>
.form-control {
  border: 1px solid var(--border-color);
  padding: 10px 15px !important;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 50px;

  label {
    top: 50%;
    line-height: 1;
    transform: translateY(-50%);
    position: absolute;
    pointer-events: none;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    font-size: 0.9em;
  }

  input:not([type="submit"]) {
    border: 0;
    position: relative;
    top: 65%;
    left: 0;
    width: 100%;
    height: 90%;
    transform: translateY(-50%);
    background: transparent;

    &:active,
    &:focus {
      outline: 0;
    }
  }
}
.clicked-input {
  font-size: 0.7em !important;
  top: 13px !important;
  color: #bebebe;
}
</style>
