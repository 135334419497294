import { createApp } from "vue";

// Import Vuex store
import store from "./store/index";

// Import Vue Router
import { createRouter, createWebHistory } from "vue-router";

// Import general app screen
import App from "./App.vue";

// Import pages
import Login from "./components/LoginForm/LoginForm.vue";
import Company from "./pages/Company/Company.vue";
import Profile from "./pages/Profile.vue";
import CompanyItem from "./pages/Company/_id/index.vue";

// CSS for layout
import "./assets/css/flexgrid.min.css";

// Create Vue Router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/company",
      component: Company,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      component: Login,
      props: true,
      meta: {
        requiresUnauth: true,
      },
    },
    {
      name: "company-detail",
      path: "/company/:id",
      component: CompanyItem,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/edit-profile",
      component: Profile,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
    next("/login");
  } else if (to.meta.requiresUnauth && store.getters.isLoggedIn) {
    next("/dashboard");
  } else {
    next();
  }
});

// Import base UI components
import BaseInput from "./components/UI/BaseInput.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BasePageTitle from "./components/UI/BasePageTitle.vue";
import BaseSpinner from "./components/UI/BaseSpinner.vue";
import BaseNotification from "./components/UI/BaseNotification.vue";

const app = createApp(App);

// Use base UI components
app.use("base-input", BaseInput);
app.use("base-button", BaseButton);
app.use("base-button", BasePageTitle);
app.use("base-spinner", BaseSpinner);
app.use("base-notification", BaseNotification);

//Use Vuex
app.use(store);

// Use Vue Router
app.use(router);
// Mount the app
app.mount("#app");
