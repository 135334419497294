<template>
  <section id="admin-root">
    <the-header v-if="isLoggedIn"></the-header>
    <aside v-if="isLoggedIn">
      <accordion-menu></accordion-menu>
    </aside>
    <main v-if="isLoggedIn">
      <router-view></router-view>
    </main>
    <LoginForm v-if="!isLoggedIn" />
  </section>
</template>

<script>
import AccordionMenu from "./components/AccordionMenu/AccordionMenu";
import TheHeader from "./components/TheHeader/TheHeader";
import LoginForm from "./components/LoginForm/LoginForm";

export default {
  name: "App",
  components: {
    AccordionMenu,
    TheHeader,
    LoginForm,
  },
  created() {
    this.$store.dispatch("tryLogin");
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    configItem() {
      return this.$store.state.config.configItem;
    },
  },
  methods: {
   
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Extra Bold";
  src: url("./assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
</style>
